const messages = {
  copy: {
    first: 'HP block',
    last: 'was copied'
  },
  list: {
    id: 'Id',
    type: 'Type',
    title: 'Title',
    identifier: 'Identifier',
    number_of_items: 'Number of items',
    external_fallback: 'External fallback',
    internal_fallback: 'Internal fallback',
    fallback_article_types: 'Fallback article types',
    fallback_article_flags: 'Fallback article flags',
    hidden: 'Hidden',
    position: 'Position',
    created_at: 'Created at',
    modified_at: 'Modified at',
    created_by: 'Created by',
    fallback_settings: 'Fallback settings'
  },
  filter: {
    site: 'Site',
    type: 'Type',
    title: 'Title',
    identifier: 'Identifier',
    id: 'Id',
    search: 'Search'
  },
  id: 'Id',
  hidden: 'Hidden',
  type: 'Type',
  title: 'Title',
  identifier: 'Identifier',
  site: 'Site',
  category: 'Category',
  number_of_items: 'Number of items',
  position: 'Position',
  daysLimit: 'Query limit days',
  display_position: 'Display position',
  disabled_positions: 'Disabled positions',
  fallback_positions: 'Fallback positions',
  external_fallback: 'Enable external fallback',
  internal_fallback: 'Enable internal fallback',
  fallback_site: 'Fallback site',
  fallback_rubrics: 'Fallback rubrics',
  fallback_tags: 'Fallback tags',
  fallback_article_types: 'Article types (logical OR will be apploed)',
  fallback_article_flags: 'Article\'s Flags (logical OR will be applied)',
  fallback_article_settings: 'Article\'s Settings',
  fallback_categories: 'Fallback categories',
  content_block_type_value: {
    article: 'Article',
    video_article: 'Video article',
    video: 'Video'
  },
  main_settings: 'Main settings',
  fallback_settings: 'Fallback settings',
  gallery: 'Gallery',
  pr: 'Only PR articles'
}

export default messages
